import style from './soup.module.scss'

const SvgSoup = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 700 400' className={style.soup}>
    <defs>
      <linearGradient id='xl-stroke' x1='0' y1='0.5' x2='1' y2='0.5'>
        <stop offset='0%' stopColor='#0036a4' />
        <stop offset='49%' stopColor='#6a2364' />
        <stop offset='100%' stopColor='#4ca8ff' />
      </linearGradient>
      <linearGradient id='l-stroke' x1='0' y1='0.5' x2='1' y2='0.5'>
        <stop offset='0%' stopColor='#b731e4c7' />
        <stop offset='48%' stopColor='#2b6dc4' />
        <stop offset='100%' stopColor='#ff00f6' />
      </linearGradient>
      <radialGradient id='m-stroke' cx='0' cy='0' r='0.5' gradientTransform='translate(0.5 0.5)'>
        <stop offset='0%' stopColor='#22388585' />
        <stop offset='100%' stopColor='#b67c89b0' />
      </radialGradient>
      <linearGradient id='sm-stroke' x1='0' y1='0.5' x2='1' y2='0.5'>
        <stop offset='0%' stopColor='#fff' />
        <stop offset='48%' stopColor='#359fad77' />
        <stop offset='100%' stopColor='#fff' />
      </linearGradient>
    </defs>
    <path
      d='M60.541022,192.774999C21.003392,149.132773,93.618712,74.10143,146.858559,55.491904c25.3958-8.876883,61.369124-18.889738,84.461248-.683004c42.516928,33.522007,12.613092,96.213556-20.419205,126.355089-13.578345,12.390061-44.521289,36.551454-69.610911,27.320018-33.103858-12.180175-16.823018-54.569106-5.568868-73.081053c24.07487-39.600761,95.400244-87.472814,159.641028-71.715053c82.361973,20.202746-34.858651,121.117467,5.568868,152.992112c5.987432,4.720727,20.191563,5.768409,27.844362,5.464007c79.76103-3.172666,61.156692-66.684253,111.37746-71.032049c39.016973-3.377841,25.565954,56.863245,91.886406,71.032049c51.281274,10.955813,113.471303-35.496578,103.024153-72.398049-11.110162-39.243393-64.628714-56.266562-100.239713-19.807016-63.22493,64.731463-24.457648,130.034964-131.796666,180.995126-33.182731,15.753795-73.719869,28.091627-113.23375,30.735022-67.502613,4.515784-159.249621-31.311075-119.730779-89.473065'
      opacity='0.16'
      fill='none'
      stroke='url(#xl-stroke)'
      strokeWidth={60}
      strokeLinecap='round'
      strokeDasharray='0.01,300'>
      <animate attributeName='stroke-dashoffset' dur='12s' from='0' to='300' repeatCount='indefinite' />
    </path>
    <path
      d='M24.64077,109.435733c9.77732-43.169398,95.29382-75.504329,126.227798-37.565028c28.480004,34.929606-79.556979,105.03965-48.263567,133.185096c9.979064,8.975215,99.164752-4.578146,116.018189-5.464007c93.293239-4.903741,184.235846,9.270784,259.880752,51.908037c26.810744,15.111878,73.176735,45.294034,53.832447,73.764048-7.509408,11.051993-27.178976,16.53514-41.766549,20.490012-72.26474,19.591865-195.886634,17.364906-222.754932-49.859033-8.777012-21.959907-7.219284-41.875131,11.137747-60.787039c36.960368-38.077569,109.24079-44.427048,143.862563-82.643059c45.553107-50.282182-10.10387-104.320783-87.245677-99.718067-42.685767,2.546875-79.978052,61.797144-30.628802,76.496056c68.896959,20.521288,142.09535-9.350216,213.473474,30.735022c43.562291,24.464147,12.890189,75.964408,54.760586,100.401071c23.011575,13.43016,92.927235,11.470796,80.748659,45.07803-20.769684,57.314699-107.56676,28.154064-159.641028,20.490012-63.418102-9.333576-87.982399-26.344666-135.509244-55.32304-16.857656-10.278555-35.484888-18.599492-51.048007-30.052018-14.029983-10.324335-24.884098-23.547639-42.694699-30.735022-32.915602-13.282934-81.457256-4.09275-112.305611,8.87901-107.102691,45.03677-54.968135,97.087033,39.91026,113.37808c52.629079,9.03665,163.631469-6.14701,185.91444,89.462991'
      opacity='0.2'
      fill='none'
      stroke='url(#l-stroke)'
      strokeWidth={30}
      strokeLinecap='round'
      strokeDasharray='0.01,500'>
      <animate attributeName='stroke-dashoffset' dur='14s' from='0' to='500' repeatCount='indefinite' />
    </path>
    <path
      d='M26.29082,129.844858c0-15.465818,7.021983-31.880026,10.209597-47.127034c3.675859-17.58235-1.283208-51.871363,15.778476-65.56805c7.254969-5.824098,10.187693-4.438835,21.347344-4.781003c44.095413-1.352034,75.299062,40.167369,79.82052,67.617046c1.684616,10.227286-7.824934,56.033877,0,59.421039c46.395702,20.083237,166.202722-38.329998,186.557251,28.003022c16.764631,54.633952-138.807219,98.865783-184.700962,120.891082-12.842152,6.163195-58.920516,25.248961-51.048007,41.663027c12.518042,26.099919,135.02659,10.700867,161.497328.683004c11.657414-4.411755,21.731033-12.191202,30.628802-19.124012c4.358671-3.39612,8.747459-6.772835,12.994036-10.24501c1.034833-.846116,2.481064-3.848227,2.78444-2.731999c2.48778,9.153485-67.929412,74.694531-32.485091,79.911059c27.169751,3.998715,71.619907-44.941691,80.748659-56.006036c41.237613-49.981299,50.820725-106.474792,80.748659-159.139115c9.536583-16.781576,28.861241-53.792314,57.545025-59.421039c39.209864-7.694294,46.88703,27.19233,50.119857,47.81003c2.460285,15.690696,3.503162,32.738444,1.856289,48.493034-2.884946,27.598558-40.408551,109.494404-6.497018,129.770093c12.529584,7.491428,29.336909,7.793429,44.550989,6.147003c65.550531-7.093686,40.139814-69.395391,71.467201-99.035071c10.725607-10.147787,32.654965,15.333999,24.131784,34.150025'
      fill='none'
      stroke='url(#m-stroke)'
      strokeWidth={6}
      strokeLinecap='round'
      strokeDasharray='0.01,72'>
      <animate attributeName='stroke-dashoffset' dur='11s' from='0' to='360' repeatCount='indefinite' />
    </path>
    <path
      d='M548.836753,44.624992c26.916212-47.425639,99.388234-12.558714,103.049491,22.466344c3.251243,31.102646-70.55417,52.435347-66.826483,90.839065.879618,9.062024,10.329678,16.183054,16.706615,23.222019c22.659712,25.01212,36.15069,53.012162,25.059934,83.326063-6.256675,17.101086-71.228523,57.873574-88.173827,41.663027-16.765407-16.038445,14.01447-20.909212,28.772513-18.441016c44.400398,7.42573,39.199862,48.178358,11.137747,65.568042-18.861882,11.68843-40.657433,17.627774-64.970182,18.441016-12.759071.426775-28.762073.008452-38.05397-7.513002-14.587089-11.807732-11.245004-34.497301-15.778476-49.176038-8.945896-28.965543-56.379513-49.881526-94.670845-41.663027-50.048296,10.741896-52.094214,78.458176-20.419205,101.76707c15.367315,11.308446,43.972474,9.148501,51.048007-7.513002c2.032339-4.785771-3.04294-10.393113-5.568868-14.343009-15.043565-23.524194-33.730117-41.728469-71.467212-43.712031-33.131218-1.741464-71.203717,3.688551-103.024153,10.24501-46.009639,9.480073-118.415138,48.817966-164.281757,18.441016-30.66551-20.309424,12.75807-68.343305,39.91026-79.911059c32.212799-13.723741,72.026579-3.504685,106.736732-6.147003c52.230596-3.976064,154.450764-47.459715,159.641028-89.473065c2.788051-22.568321-26.790438-36.532239-53.832435-40.297027-66.712859-9.287755-138.894203-1.825607-204.192017-19.124012-38.477878-10.193385-22.449204-66.152738,9.281458-77.179052C202.82374,-6.519584,308.91142,55.028095,392.933642,79.385358c32.624072,9.457415,97.636197-12.294022,85.5703-86.837121'
      fill='none'
      stroke='url(#sm-stroke)'
      strokeWidth={2}
      strokeLinecap='round'
      strokeDasharray='0.01,72'>
      <animate attributeName='stroke-dashoffset' dur='12s' from='0' to='360' repeatCount='indefinite' />
    </path>
  </svg>
)

export default SvgSoup
